import React from 'react';

export default function Account() {
  return (
    <div className="page-container">
      <h1>Account</h1>
      <p>Coming Soon</p>
    </div>
  );
}
