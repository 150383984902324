// src/pages/Services.js
import React, { useState, useEffect } from 'react';
import '../css/Services.css';

export default function Services() {
  const services = [
    {
      title: "Contract Nurse Staffing",
      description: "Delivering skilled nursing professionals to healthcare facilities to meet your staffing needs with reliability and care.",
    },
    {
      title: "Companionship",
      description: "Offering compassionate companionship and attentive supervision to enhance the well-being of your loved ones.",
    },
    {
      title: "Dementia Care",
      description: "Providing expert, empathetic care tailored to the unique needs of individuals living with dementia.",
    },
    {
      title: "Supportive Care",
      description: "Extending supportive care services to adults of all ages who require assistance in their daily lives.",
    },
    {
      title: "Personal Care",
      description: "Helping your loved ones maintain their independence with dignified support for daily activities and personal needs.",
    },
  ];
  
  const [background, setBackground] = useState('/src/assets/Home4.webp');

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      // Change background based on scroll position
      if (scrollTop < 500) {
        setBackground('/src/assets/Home4.webp');
      } else {
        setBackground('/src/assets/Home2.webp');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className="services-page"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        color: '#1c3c9a',
      }}
    >
      <div className="page-container">
        <h1>Services</h1>
        <p className="subheading">Explore our wide range of professional services.</p>
        <div className="service-list">
          {services.map((service, index) => (
            <div className="service-card" key={index} role="article" aria-labelledby={`service-title-${index}`}>
              <h2 id={`service-title-${index}`}>{service.title}</h2>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
