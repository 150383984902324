// src/components/Footer.js
import React from 'react';
import useIsMobile from '../hooks/useIsMobile';
import '../css/Footer.css';

const Footer = () => {
  const isMobile = useIsMobile(); // Use the hook inside the component body

  return (
    <footer className={`footer ${isMobile ? 'footer-mobile' : 'footer-desktop'}`}>
      <p>© 2024 JNN Professional Services</p>
    </footer>
  );
};

export default Footer;
