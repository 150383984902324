/**
 * @file NursingContracts.js
 * @description React component for displaying Travel Nurse opportunities by fetching data 
 *              from the backend, sorting, and presenting it in a user-friendly way.
 * @module NursingContracts
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/NursingContracts.css";
import SortIcon from "../assets/sort-vertical.svg"; // Sorting icon

export default function NursingContracts() {
  // State variables
  const [opportunities, setOpportunities] = useState([]); // All opportunities fetched
  const [sortedOpportunities, setSortedOpportunities] = useState([]); // Opportunities after sorting
  const [sortOrder, setSortOrder] = useState("asc"); // Sorting order (ascending or descending)
  const [loading, setLoading] = useState(true); // Tracks loading state
  const [errorMessage, setErrorMessage] = useState(""); // Stores error messages

  // Backend URL - Uses environment variable if set, otherwise defaults to localhost
  const backendUrl = `${
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5003/api"
  }/nursing-contracts`;
  

  /**
   * Fetches data from the backend and updates state variables.
   * Handles loading state and error messages appropriately.
   */
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Begin loading
      try {
        const response = await axios.get(backendUrl); // Fetch data from backend
        const filteredRows = response.data; // Extract data from the response

        setOpportunities(filteredRows); // Store the data
        setSortedOpportunities(filteredRows); // Initialize sorted data
        setErrorMessage(""); // Clear previous error messages
      } catch (error) {
        console.error("Error fetching data from backend:", error); // Log error
        setErrorMessage("Unable to fetch opportunities. Please try again later."); // Display error message
      }
      setLoading(false); // End loading
    };

    fetchData(); // Call the fetch function
  }, [backendUrl]);

  /**
   * Handles sorting of opportunities based on the posting date.
   * Toggles between ascending and descending order.
   */
  const handleSort = () => {
    const sorted = [...sortedOpportunities].sort((a, b) => {
      const dateA = new Date(a[0] || null); // Parse date or use null if invalid
      const dateB = new Date(b[0] || null);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA; // Compare dates
    });
    setSortedOpportunities(sorted); // Update sorted opportunities
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc")); // Toggle order
  };

  return (
    <div className="page-container">
      <h1>Travel Nurse Opportunities</h1>
      {loading ? (
        // Display loading spinner/message
        <p>Loading opportunities...</p>
      ) : errorMessage ? (
        // Display error message if fetching fails
        <p>{errorMessage}</p>
      ) : sortedOpportunities.length > 0 ? (
        <div>
          {/* Sort button */}
          <div className="sort-header">
            <button onClick={handleSort} className="sort-button">
              Posting Date
              <img src={SortIcon} alt="Sort" className="sort-icon" />
            </button>
          </div>
          {/* Render list of opportunities */}
          {sortedOpportunities.map((opportunity, index) => (
            <div key={index} className="opportunity-card">
              <p>
                <strong>Posted:</strong> {opportunity[0] || "N/A"};{" "}
                <strong>Role:</strong> {opportunity[2] || "N/A"}
              </p>
              <p>
                <strong>Requisition Number:</strong> {opportunity[1] || "N/A"}
              </p>
              <p>
                <strong>Start Date:</strong> {opportunity[4] || "N/A"}
              </p>
              <p>
                <strong>End Date:</strong> {opportunity[5] || "N/A"}
              </p>
              <p>
                <strong>Location:</strong> {opportunity[6] || "N/A"}
              </p>
            </div>
          ))}
        </div>
      ) : (
        // Display message if no opportunities are available
        <p>No open opportunities available at the moment.</p>
      )}
    </div>
  );
}
