import React from 'react';

export default function About() {
  return (
    <div className="page-container about-page">
      <h1>About Us</h1>
      <h2>Our Mission</h2>
      <p>
        Our mission is to deliver holistic, family-centered care that profoundly enriches the lives we touch, creating lasting positive impacts.
      </p>
      <h2>Our History</h2>
      <p>
        JNN Professional Services emerged as a response to the pressing healthcare needs exposed by the COVID-19 pandemic. Founded by Jayne, an individual with a deep-rooted commitment to family, love, and support, this healthcare company was established with the primary aim of addressing the gaps in homecare and to provide respite care for clients and their families.
      </p>
      <h2>Our Vision</h2>
      <p>
        We envision a future where our holistic, family-centered care transforms the lives of those we serve, creating a ripple effect of positivity throughout communities.
      </p>
      <h2>Core Values</h2>
      <ul>
        <li>Respect</li>
        <li>Compassion</li>
        <li>Ethical Practices</li>
        <li>Empathy</li>
        <li>Privacy and Confidentiality</li>
      </ul>
    </div>
  );
}
