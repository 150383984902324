// src/components/Navbar.js
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useIsMobile from '../hooks/useIsMobile';
import '../css/Navbar.css'; // Separate CSS for styling
import HamburgerIcon from '../assets/hamburger.svg';
import BackIcon from '../assets/back.svg';
import Logo from '../assets/JNN_logo_Color_Transparent.svg';

const Navbar = () => {
  const isMobile = useIsMobile();
  const [menuVisible, setMenuVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuVisible((prev) => !prev);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };
  
  // Navigates to the previous page
  const handleBack = () => {
    navigate(-1); 
  };

  const isHomePage = location.pathname === '/';

  return (
    <div className="navbar">
      {/* Back Button (only shown on non-home pages) */}
      {!isHomePage && (
        <button
          className="back-button"
          onClick={handleBack}
          aria-label="Go back to the previous page"
        >
          <img src={BackIcon} alt="Back" />
        </button>
      )}

      {/* Company Logo */}
      <div className="navbar-logo">
        <img src={Logo} alt="JNN Logo" />
      </div>
      
      {/* Hamburger Icon */}
      <button
        className="hamburger-menu"
        onClick={toggleMenu}
        aria-expanded={menuVisible}
        aria-label="Toggle navigation menu"
      >
        <img src={HamburgerIcon} alt="Open menu" />
      </button>

      {/* Navigation Menu */}
      <nav className={`navbar-menu ${menuVisible ? 'menu-open' : ''}`}>
        <ul>
          <li>
            <Link to="/" onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={closeMenu}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={closeMenu}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/nursing-contracts" onClick={closeMenu}>
              Opportunities
            </Link>
          </li>
          <li>
            <Link to="/account" onClick={closeMenu}>
              Account
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeMenu}>
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
