// src/pages/Home.js
import React from 'react';
import '../css/App.css';

export default function Home() {
  const links = [
    { href: '/services', text: 'Services' },
    { href: '/about', text: 'About Us' },
    { href: '/nursing-contracts', text: 'Opportunities' },
    { href: '/account', text: 'Account' },
    { href: '/contact', text: 'Contact Us' },
  ];

  return (
    <div className="page-container">
      <h1>Welcome to JNN Professional Services</h1>
      <div
        className={`links-grid ${
          links.length === 1 ? 'single-button-center' : ''
        }`}
      >
        {links.map((link, index) => (
          <a href={link.href} key={index} className="home-link">
            {link.text}
          </a>
        ))}
      </div>
    </div>
  );
}
