// src/pages/ContactUs.js
import React from 'react';

export default function Contact() {
  return (
    <div className="page-container">
      <h1>Contact Us</h1>
      <p>Send us a message!</p>
      <p>Email: <a href="mailto:admin@jnnproservices.com">admin@jnnproservices.com</a></p>
    </div>
  );
}
